var container;
var materials;

function init(data) {
  var element = document.getElementById('output');
  element.setAttribute('data-text', data);
}

// callback for all Unity events
function callbackFromUnity(res) {
  res = JSON.parse(res);
  switch (res.eventName) {
    case 'Init':
      res.logData.loaded
        ? init(
            JSON.stringify({
              event: 'responseFromInit',
              animation: res.logData.animation === 'true',
            })
          )
        : false;
      break;
    case 'TakeScreenshot':
      init(JSON.stringify({ event: 'TakeScreenshot', screenshot: res.logData.data }));
      break;
    case 'AnimationEnd':
      init(JSON.stringify({ event: 'AnimationEnd' }));
      break;
    case 'MaterialLoaded':
      setTimeout(function () {
        init(JSON.stringify({ event: 'MaterialLoaded' }));
      }, 100);
      break;
    case 'GetActiveItemId':
      let activeProduct = localStorage.getItem('activeProduct');
      if (activeProduct !== res.logData.data) {
        activeProduct = res.logData.data;
        init(JSON.stringify({ event: 'ChangeModel', productId: res.logData.data }));
      }
      break;
    default:
      break;
  }
}
